import { get, post } from 'src/utils/request'

const api_v1 = '/newretail'
const api_v2 = '/newretail/v2'
const api_v3 = '/newretail/v3'

export const getProductList = post(api_v1 + '/api/solution_package/getProductList')

export const getBoxByProductId = get(api_v1 + '/api/solution_package/getBoxByProductId')

export const productDispatch = post(api_v1 + '/api/solution_package/productDispatch')

export const getCameraList = post(api_v1 + '/api/solution_package/getCameraList')

export const updateFloorNum = post(api_v1 + '/api/camera/updateFloorNum')
export const batchUpdateLiveVideoOnly = post(api_v1 + '/api/camera/batchUpdateLiveVideoOnly')

/** 查询硬编解码和视频配置 */
export const getMngInfo = post(api_v1 + '/api/camera/getmnginfo')

/** 设置硬编解码 */
export const updateHardConfig = post(api_v1 + '/api/camera/updateHardConfig')

// 安防监控
export const getCameraByBox4Security = get(api_v1 + '/api/solution_package/getCameraByBox4Security')
export const bindCamera4Security = post(api_v1 + '/api/solution_package/bindCamera4Security')
export const unbindCamera4Security = post(api_v1 + 'api/solution_package/unbindCamera4Security')

// v3 （适用于产品管理）
export const apiv3 = {
  bindCamera: post(api_v3 + '/api/camera/bindtoagent'),
  replaceCamera: post(api_v3 + '/api/camera/replace'),
}

// 解决方案
export const solution = {
  saveByShop: post(api_v1 + '/api/solution_package/sp2/saveByShop'),
  addBox: post(api_v1 + '/api/solution_package/sp2/addBox'),
  addNvr: post(api_v1 + '/api/solution_package/sp2/addNvr'),
  deleteById: post(api_v1 + '/api/solution_package/sp2/deleteById'),
  getBoxByProductId: get(api_v1 + '/api/solution_package/sp2/getBoxByProductId'),
  getBoxPageForAdd: post(api_v1 + '/api/solution_package/sp2/getBoxPageForAdd'),
  removeBox: post(api_v1 + '/api/solution_package/sp2/removeBox'),
  saveByBox: post(api_v1 + '/api/solution_package/sp2/saveByBox'),
  batchSetSpeedLimit: post(api_v2 + '/api/agent/batchSetSpeedLimit'),
}
